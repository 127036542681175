const style = {
  styleMode: {
    dark: {
      main: "tw-text-white",
      second: "tw-text-gray-400",
    },
    light: {
      main: "tw-text-black",
      second: "tw-text-gray-700",
    },
  },
};
export default style;
